{
  "country_code": "971",
  "neighborhoods": false,
  "toMiles": false,
  "currency_config_up": "AED",
  "masks": {
    "mi": "KM",
    "phone": "00 000 0000",
    "cell": "00 000 0000",
    "secret_number": "000-0000-0000000-0",
    "ssn_mask": "000-0000-0000000-0",
    "zipcode": "000099",
    "date_mask": "dd/MM/yyyy",
    "date_mask_format_m": "DD/MM/YYYY",
    "date_mask_two": "dd/MM",
    "states": []
  },
  "sale_without_registration": "Sale without registration",
  "i_dont_need_indentify_client": "I don't need to identify the client",
  "closing": "Closing",
  "uf": "States",
  "contact": "Contact",
  "favorite_card_comment": "Ex: My favorite card",
  "finalize_order_comment": "Finalize Order",
  "goodbye": "goodbye!",
  "opened": "opened",
  "no_categories_available_at_moment": "No categories available for table-type orders at the moment.",
  "check_admin_panel_categories_active": "Check the admin panel to ensure that categories are active and available for table-type orders.",
  "cart_empty": "Cart Empty",
  "optional": "optional",
  "add_comment": "+Add",
  "obs": "Observations",
  "my_orders": "my orders",
  "tax_and_time_of_shipping": "tax and time of shipping",
  "scheduling": "Scheduling",
  "scheduling2": "Scheduling",
  "package_s": "Packages",
  "close_order": "close order",
  "verify": "verify",
  "add_more_items": "add more items",
  "next": "Next",
  "edit_item": "edit item",
  "select_shipoing": "Select Shipping",
  "take_out": "take out",
  "cupom": "coupon",
  "active_your_cupom": "active your coupon",
  "code_of_cupom": "code of coupon",
  "total": "total",
  "select_the_form_of_shipping": "select the form of shipping",
  "obrigatory": "obrigatory",
  "go_to_payment": "go to payment",
  "pay_on_app": "pay on app",
  "pay_on_shippingg": "pay on shipping",
  "my_cards": "my cards",
  "discont": "Discount",
  "payment_in_comment": "Payment in",
  "i_need_transhipment": "need transhipment to",
  "i_not_need_transhipment": "Don't Need Transhipment",
  "secret_number_on_note": "SSN on note",
  "search_products_by_name_or_description": "Search Products by Name or Description",
  "outside_the_coverage_area": "outside the coverage area",
  "unavilable": "Unavilable",
  "avaible": "avaible",
  "product_unavilable": "product unavilable",
  "unavilable_amount": "unavilable amount",
  "table_s": "tables",
  "discont_of": "discont of",
  "free_freight": "Free Freight",
  "free_freight_comment": "Free Freight",
  "free_comment_up": "FREE",
  "covered_address": "Covered Address",
  "send_order": "send order",
  "you_are_blocked": "you are blocked",
  "enter_your_cell": "enter your cell",
  "enter_your_email": "enter your email",
  "enter_your_address": "enter your address",
  "enter_you_ddd": "enter your cell with DDD",
  "minimum_unconceived_value": "minimum unconceived value",
  "finish_payment_pix": "finish payment pix",
  "finish_payment": "finish payment",
  "payment_online": "payment online",
  "payment_offline": "payment offline",
  "add_new_card": "add new card",
  "pay_on_delivery": "pay on delivery",
  "i_need_change_for": "i need change for",
  "i_dont_need_change": "i don't need change",
  "send_the_receipt": "send the recepit to our whatsapp to expedite your service",
  "copy_key": "copy key",
  "select_the_flag": "select the flag",
  "select_the_flag_comment": "Select the Flag",
  "cpf_on_the_receipt": "SSN on the receipt",
  "how_to_pay_with_pix": "How to pay with PIX",
  "enter_cpf_generate_pix": "Enter your SSN to generate a PIX key",
  "enter_cpf": "Enter your SSN",
  "enter_a_valid_cpf": "Enter a valid SSN",
  "order_pending_payment": "order pending payment",
  "generate_pix_key": "Generate PIX key",
  "pix_copy_paste": "PIX Copy and paste",
  "copy_code": "copy coding",
  "pix_key_expired": "Your PIX key has expired. Click the button to generate a new code",
  "paste_this_code_your_bank": "Past this code in the Copy and Paste PIX option your bank",
  "verify_pix_payment": "Verify PIX Payment",
  "card_details": "card details",
  "name_on_the_card": "name on the card",
  "card_number": "card number",
  "expiry": "expiry",
  "card_nickname": "card nickname (optional)",
  "billing_adress": "billing adress",
  "zip_code_not_found": "ZIP Code not found",
  "adress_line_2": "adress line 2 (optional)",
  "order_will_be_delivered": "order will be delivered to",
  "to_consult": "to consult",
  "flavors_comment": "Flavors",
  "flavor_comment": "Flavor",
  "crust": "crust",
  "general_toppings": "general toppings",
  "single_crust": "single crust",
  "message_cvv_payment": "Enter the CVV code from your card to complete the payment",
  "code_back_of_your_card": "this code is located on the back of your card",
  "complete_payment": "complete payment",
  "what_card_nickname": "what is this card's nickname",
  "what_your_name": "What's your name",
  "open_new_order": "Open new order",
  "enter_name_your_order": "Enter a name for your order",
  "select_order": "Select an order",
  "addition": "Addition",
  "use_cashback": "use cashback balance",
  "balance": "balance",
  "indentify_yourself": "indentify yourself",
  "whatsapp_number": "whatsapp number",
  "date_of_birth": "date of birth",
  "place_order_necessary_information": "to place the order, it is necessary to fill out the above information",
  "order_slip_s": "Order slips",
  "no_active_orders": "no active orders",
  "order_sucess": "order placed successfully",
  "send_confirmation_whatsapp": "send confirmation via whatsapp",
  "select_delivery_date": "select a delivery date",
  "time_zone_is_different": "We have identified that your time zone is different from the establishment's time zone. Your corresponding receiving time will be shown next to the time",
  "delivery_time_between": "choose a delivery time between the periods",
  "delivery_for_order": "choose a delivery for your order",
  "establishment_notes": "establishment notes",
  "no_more_avaible_slots_for_orders": "we have no more avaible slots for orders. please contact our establishment",
  "contact_establishment": "contact the establishment",
  "no_slots_avaible_for_orders_on_that_day": "we have no more slots avaible for orders on that day",
  "please_select_another_date": "please select another dater.",
  "continue": "continue",
  "last_orders": "Last Orders",
  "view_order": "view order",
  "edit_addresses": "edit addresses",
  "addresses": "addresses",
  "add_new_addresses": "add new addresses",
  "create_address_or_use_list_below": "create a new address or use the list below",
  "more_information": "more information",
  "notification_permissions": "to recive notifications, you need to grant notification permissions on your device.",
  "opening_hours": "opening hours",
  "from_de": "From",
  "to": "to",
  "pay_online": "pay online",
  "choose_this_option_secure_payment": "choose this option to make a secure payment through our online system.",
  "choose_option_receiving": "prefer to pay upon receiving your order? choose this option.",
  "acess_your_payment_app": "acess your payment app",
  "qrcode_copy_paste_payment": "scan the qr code or copy and paste the payment code",
  "pay_credited_instantly": "pay and it will be credited instantly",
  "enter_your_cpf": "Enter your SSN",
  "local_pickup": "local pickup",
  "payment_still_pending": "payment still pending. please try again.",
  "confirmation_is_taking_too_long": "if the confirmation is taking too long, click the button to proceed.",
  "use_delivery_address": "use delivery address",
  "state": "state",
  "cash_payment": "cash payment",
  "payment_meal_voucher": "payment by meal voucher.",
  "payment_food_voucher": "payment by food voucher",
  "receipt_to_our_whatsapp": "send the receipt to our whatsapp to expedite your service",
  "total_to_transfer": "total to transfer",
  "type_of_key": "type of key",
  "save_future_purchases": "save for future purchases",
  "finalize_order": "finalize order",
  "add_on": "Add-on",
  "error_generating_qrcode": "Error generating QR code. Please try again.",
  "payment_not_detected_check_banking": "Payment not detected. Please check your banking app.",
  "error_processing_payment": "Error processing the payment, please try again.",
  "error_creating_customer": "Error creating customer. Please try again.",
  "choose_single_crust": "choose the single crust",
  "choose_single_crust_comment": "Choose Single Crust",
  "choose_crust_comment": "Choose Crust",
  "crust_without_filling": "crust without filling",
  "choose_the": "choose the",
  "choose_the_o": "choose the",
  "complete_order_mandotory": "complete your order with the mandatory items",
  "no_orders_moment": "no orders at the moment",
  "fee_comment": "Fee",
  "pickup_only": "pickup only",
  "loading_dates": "loading dates",
  "choose_date": "choose a date",
  "see_full_list": "click here to see the full list of products",
  "no_products_found": "sorry, no products found",
  "size_unavilable": "size unavailable",
  "edit_notes": "edit notes",
  "remove_item": "remove item",
  "point_camera_qrcode": "please point your camera at the QRCode on the table where you wish to place your order",
  "order_status": "order status",
  "in_preparation": "in preparation",
  "canceled": "canceled",
  "order_received": "order received",
  "pending_payment": "pending payment",
  "order_approved": "order approved",
  "order_preparation": "order in preparation",
  "order_canceled": "order canceled",
  "order_out_for_delivery": "order is out for delivery",
  "order_ready_for_pickup": "order ready for pickup",
  "send_order_whatsapp": "send order via whatsapp",
  "reference_point": "reference point",
  "house_corner": "House corner",
  "address_complement": "Address Complement",
  "block_apt": "Block, Apt",
  "ave": "Ave. Times Square",
  "date_usa": "mm/dd/yyyy",
  "choose_time": "Choose a Time",
  "minimum_quantity": "Minimum quantity",
  "maximum_quantity": "Maximum quantity",
  "repeat_order": "repeat order",
  "pick_up_counter": "Pick up at the Counter",
  "counter_and_delivery": "Counter/Delivery",
  "discount_comment": "Discount",
  "discount_of": "discount of",
  "applied": "applied",
  "change_address": "change address",
  "message_no_costumer_selected": "No customer selected for this order. Please search for a registered customer or register a new one to proceed.",
  "search_customer": "search customer",
  "add_customer": "add customer",
  "outside_service_area": "OUTSIDE THE SERVICE AREA",
  "free_comment": "Free",
  "activate_coupon_comment": "Activate Your Coupon",
  "order_type": "order type",
  "place_order": "place order",
  "open_register": "open register",
  "initial_balance": "Initial Balance $",
  "initial_balance_n": "Initial Balance",
  "income": "income",
  "cash_register_status": "cash register status",
  "cash_outflow": "cash outflow",
  "close_cash_register": "close cash register",
  "cash_balance": "cash balance",
  "cashier_balance": "cashier balance",
  "final_balance": "final balance",
  "new_order": "New Order",
  "create_order_slip": "create order slip",
  "change_tables": "change tables",
  "table_change": "table change",
  "print_table": "print table",
  "close_table": "close table",
  "client_comment": "Customer",
  "no_registred_address": "no registred address",
  "none": "none",
  "search_name_comment": "Search by Name",
  "search_flavor_comment": "Search by Flavor",
  "waiting_service": "waiting for service",
  "pause_comment": "Pause",
  "unpause_comment": "Unpause",
  "order_buy": "order",
  "order_the_table": "order at the table",
  "registration_comment": "Registration",
  "change_comment": "Change",
  "one_more_comment": "One more",
  "customer": "customers",
  "customer_name_comment": "Customer name",
  "email_example_comment": "customer@example.com",
  "ssn_model_comment": "000-00-0000",
  "number_model_comment": "(000)000-0000",
  "order_by": "Order by",
  "delivery_address": "Delivery Address",
  "number_comment": "Number",
  "no_number": "no number",
  "dont_know_my_zip": "i don't know my ZIP",
  "other_s": "Others",
  "deliver_this_address": "Deliver to This Address",
  "out_coverage_area": "out of coverage area",
  "finalize_appointment": "finalize appointment",
  "return_cancel": "Return/Cancel",
  "return": "Return",
  "remaining": "remaining",
  "fee_discount": "Fee/Discount",
  "how_pay_pix": "How To Pay With PIX",
  "step": "step",
  "payment_deadline": "the payment deadline is in",
  "select_comment": "Select",
  "call_back_house": "Ex: Call at The Back House",
  "change_for_comment": "Change For",
  "for": "for",
  "out_for_delivery": "out for delivery",
  "waiting_for_pickup": "waiting for pickup",
  "add_flavor": "add flavor",
  "your_flavor": " your flavor",
  "choose": "Choose",
  "selected_date_is_earlier": "the selected date is earlier than the current date",
  "register_closing": "cash register closing",
  "confirm_register_closing": "confirm cash register closing",
  "closing_insert_following": "during the closing, insert the following amounts into your cash drawer",
  "register_login": "cash register login",
  "deposit": "Deposit",
  "purpose": "purpose",
  "list": "list",
  "make_payment": "make payment",
  "uncancel_order": "uncancel order",
  "order_amount": "total order amount",
  "print_the": "print the",
  "next_flavor_comment": "Next Flavor",
  "choose_flavor_comment": "Choose a Flavor",
  "quantity_unavaible": "Quantity Unavaible",
  "complete_toppings_comment": "Complete Toppings",
  "out_stock_toppings_comment": "Out of Stock Toppings",
  "in_stock_comment": "In Stock",
  "the_flavors_comment": "the Flavors",
  "the_crust_comment": "the Crust",
  "the_toppings_comment": "the Toppings",
  "mandatory_to_choose": "it is mandatory to choose",
  "time_closed_comment": "Time Closed!",
  "change_less_purchase_comment": "Change less than the purchase amount",
  "free_shipping_comment": "FREE SHIPPING",
  "consult_comment": "Consult",
  "location_comment": "Location",
  "paid_online_comment": "Paid Online",
  "track_order": "Track order",
  "street": "Street",
  "order_comment": "Order",
  "schedule": "Schedule",
  "closed_orders": "Closed Orders",
  "scheduling_closed": "Scheduling Closed",
  "order_name": "order name",
  "no_product_found": "no product found",
  "items_found": "items found",
  "mandatory_items": "mandatory items",
  "minimum": "minimum",
  "advance": "Advance",
  "loading_page": "Loading...",
  "enter_city": "Enter the city",
  "select_city": "Select the city",
  "enter_neighborhood": "Enter the neighborhood",
  "select_neighborhood": "Select the neighborhood",
  "enter_street_name": "Enter the street name",
  "coupon_only_used_purchases": "This coupon can only be used on purchases starting from",
  "discount_coupon_greater_total": "The discount on the coupon is greater than the total amount!",
  "add_to_order": "Add to Order",
  "data_print": "Date print",
  "generate_fiscal_note": "Issue Invoice",
  "alert": {
    "unable_to_read": "Unable to read your location, please check if location services are not blocked.",
    "address_covarage_area": "Your address is outside our coverage area!",
    "register_open_24": "There is a cash register open for more than 24 hours, and it will be closed.",
    "table_desactived": "Sorry, this table is currently desactived",
    "moment_table_not_avaible": "At the moment, this table is not avaible for new orders",
    "table_not_avaible_new_orders": "At the moment, this table is not available for new orders.",
    "order_already_been_completed": "The previous order has already been completed. Are you still at the restaurant, or would you like to place a new order for delivery?",
    "at_the_table": "I'm at the table",
    "deliver_order": "Deliver order",
    "not_belong_this_table": "This order does not belong to this table.",
    "store_closed": "STORE CLOSED!",
    "not_minimum_value": "Your order did not meet the minimum value of",
    "failed_validate_coupon": "Failed to validate coupon, please check your connection!",
    "amount_not_include_delivery_fee": "This total amount does not include the delivery fee.",
    "coupon_used_starting_from": "This coupon can only be used for purchases starting from",
    "closed_delivery": "CLOSED FOR DELIVERY",
    "unable_create_customer": "Unable to create customer. Please try again.",
    "selected_date_earlier_choose_later_date": "The selected date is earlier than the current date, please choose a later date",
    "try_again_moment": "Something unexpected happened, please try again in a moment.",
    "error_generate_qrcode": "Unable to generate QR Code. Please try again.",
    "payment_not_detected": "Payment not detected. Please check your bank app.",
    "reloaded_page_message": "The page needs to be reloaded!",
    "name_and_phone_required": "Name and phone number are required",
    "enter_your_details": "Enter your details",
    "enter_your_name": "Enter your name",
    "enter_your_whatsapp": "Enter your Whatsapp",
    "enter_your_date_of_birth": "Enter your date of birth",
    "review_change_amount": "Review the Change Amount!",
    "no_internet_connection": "No Internet Connection",
    "add_another_card": "Add another card",
    "zip_invalid": "Zip code is invalid"
  },
  "calendar": {
    "weekdays": ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    "months": [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "Agust",
      "September",
      "October",
      "November",
      "December"
    ]
  },
  "timezones": [
    {
      "label": "Dubai",
      "value": "Asia/Dubai"
    }
  ],
  "unitSystem": "imperial",
  "distanceinkmto": "Distance in miles to",
  "distance": "Distance",
  "distance_added_successfully": "Distance added successfully",
  "the_distance_already_exists": "This distance already exists",
  "distance_deleted_successfully": "Distance deleted successfully",
  "update_shipping": "Update Shipping",
  "tobeconfirmed": "To be confirmed",
  "km": "Miles",
  "time": "Time",
  "zelle": "Zelle",
  "twint": "Twint",
  "coin": ".د.إ",
  "currency_config": "aed",
  "ddi": "971",
  "date_format": "dd/MM/yyyy",
  "date_format_v2": "dd/MM",
  "welcome": "Welcome",
  "description": "Description",
  "orders": "Orders",
  "order": "Order",
  "order_management": "Order Management",
  "new_feature": "New Feature",
  "work_waiters_taking": "Do you work with waiters taking orders?",
  "register_you_waiters_quickly": "Then register your waiters and quickly place orders.",
  "dont_work_waiters": "I don't work with waiters",
  "register_waiters": "Register my waiters",
  "orders_were_not_received": "Orders that were not received.",
  "orders_appointments": "Orders/Appointments",
  "efficiency": "Efficiency",
  "incredible_agility": "Incredible agility in managing tables and orders like never seen before; this system is everything you’ve always dreamed of to earn more money and organize your restaurant.",
  "automation": "Automation",
  "now_you_control": "Now you control the tables and orders at your restaurant automatically, without errors or extra labor to take the orders.",
  "qrcode_self_service": "QR code self-service technology by table; no need for a waiter to take orders.",
  "self_service": "Self-service",
  "customers_place_orders_themselves": "With this self-service, your customers place their orders themselves at the table, and the orders are automatically printed directly in your kitchen in the order they are received.",
  "zero_waste": "Zero waste",
  "indecipherable_handwriting": "Say goodbye to those indecipherable handwriting, errors, and losses in dish preparation. Keep everything running smoothly and efficiently in your restaurant.",
  "closure": "Closure",
  "automatically_separates_calculates": "Quick bill settlement directly at the table or at the cashier. WhatsMenu automatically separates and calculates what each order consumed, allowing you to charge customers individually if they leave before the table is closed, ensuring smooth and error-free transactions.",
  "ordering_system": "The Ordering System",
  "allows_your_customer": "Allows your customer to choose the day and time to receive their order.",
  "allows_customer_they_wish": "Allows the customer to choose the day and time they wish to receive the order.",
  "you_can_sell_24h": "You can sell 24/7, even when your establishment is closed.",
  "prevents_setbacks": "Prevents setbacks, such as the customer being unavailable to receive the order and the delivery person making a wasted trip.",
  "advantages": "Advantages",
  "configure_days_establishment": "Configure the days and times accepted by the establishment.",
  "customer_best_time_for_them": "The customer chooses the best time for them.",
  "helps_improve": "Helps improve the organization of delivery logistics.",
  "speak_with_consultant": "Speak with a consultant now",
  "must_be_between": "The value must be between 0.49 and 0.99",
  "acess": "Acess",
  "delete_addresses": "Delete addresses",
  "addresses_deleted": "Addresses successfully deleted!",
  "control_of": "Control of",
  "error_deleting_addresses": "Error deleting addresses. Check the console for more details.",
  "user": "User",
  "sucess_updated": "successfully updated",
  "something_wrong": "Something went wrong",
  "talk_it_team": "Talk to the IT team",
  "user_profile": "User profile",
  "profile": "Profile",
  "not_registered": "Not Registered",
  "with_area_code": "With area code",
  "owner_cell_phone": "Owner cell phone",
  "owner_email_address": "Owner email address",
  "full_name": "Full name",
  "register_customer": "Register Customer",
  "select_payment_gateway_for_user": "Select the payment gateway for the user before proceeding.",
  "whatsapp": "Phone number",
  "internationalization": "Internationalization",
  "asaas_account_disconnected": "Asaas account disconnected from the profile",
  "unable_disconnect_asaas_account": "Unable to disconnect the Asaas account from the profile.",
  "add_ons": "Add-ons",
  "there_no_services_generate": "There are no services to generate",
  "boleto_generated": "Boleto successfully generated",
  "issue_add_ons": "Issue add-ons",
  "make_sure_boleto_generated": "Make sure a boleto was generated for this invoice",
  "invoice_settlement": "Invoice settlement",
  "reset_security_key": "Reset security key",
  "assign_support": "Assign support",
  "acessing_user_account": "Acessing user account",
  "logging_in": "Logging in...",
  "back_to_list": "Back to list",
  "menu": "Menu",
  "client_contracted_menu_registration": "Client has contracted menu registration",
  "printer": "Printer",
  "client_purchased_printer": "The client purchased a printer",
  "user_data": "User data",
  "salesperson": "Salesperson",
  "responsible_support": "Responsible Support",
  "name": "Name",
  "no_name": "No name",
  "no_email": "No email",
  "invalid_email": "Invalid email",
  "ssn_ein": "SSN/EIN",
  "no_ssn_ein": "No SSN/EIN",
  "invalid": "Invalid",
  "no_whatsapp": "No Phone Number",
  "registration_date": "Registration date",
  "no_date": "No date",
  "login_attempts": "Login attempts",
  "due_date": "Due date",
  "payment_method": "Payment method",
  "card": "Card",
  "password": "Password",
  "passwords": "Passwords",
  "enter_new_password": "Enter the new password",
  "security_key": "Security key",
  "reset": "Reset",
  "access_panel": "Access Panel",
  "not_have_permission_adm": "You do not have permission to access an Administrator account",
  "clear_addresses": "Clear Addresses",
  "clear": "Clear",
  "delete_addresses_customer": "Do you want to delete the addresses of this customer?",
  "no": "No",
  "yes": "Yes",
  "user_cancellation": "User Cancellation",
  "cancel_charges_user": "Cancel charges for this user?",
  "version_next": "Version *Next*",
  "test": "test",
  "activate": "Activate",
  "automatic_printing_browser": "Automatic printing through the browser",
  "observations": "Observations",
  "save": "Save",
  "title": "Title",
  "block_profile": "Block Profile",
  "value_whatsapp": "Value on Phone number",
  "enable_shipping_mi": "Enable shipping by MI",
  "double_sending_whatsapp": "Double sending on Phone number",
  "enable_pix_online": "Enable Pix Online",
  "enable_online_card": "Enable Online Card",
  "old_pix": "Old Pix",
  "negotiation_fee": "Negotiation fee",
  "days": "days",
  "expires_in": "Expires in",
  "expired_since": "Expired since",
  "disconnect_account": "Disconnect Account Asaas",
  "unlink": "Unlink",
  "account_asaas": "Account Asaas",
  "unlink_asaas_account": "Do you want to unlink the Asaas account from this customer",
  "quantity": "Quantity",
  "issue": "Issue",
  "number_installments": "Number of Installments",
  "invoices": "Invoices",
  "transaction_id": "Transaction ID",
  "value": "Value",
  "action": "Action",
  "action_remove_user_the": "This action will remove the user from the",
  "notice": "Notice",
  "settlement": "Settlement",
  "warning_financial_password": "WARNING! You need to set up a Financial Password.",
  "warning_v2": "To do this, go to the Settings option in the menu.",
  "passwords_or": "Passwords or",
  "click_here": "Click here",
  "enter_financial_password": "Enter Your Financial Password",
  "confirm": "Confirm",
  "forgot_financial_password": "Forgot your Financial Password",
  "recover_password": "Recover password",
  "check_inbox_spam": "check your 'inbox' or 'spam' folder.",
  "message_subject": "the message with the subject",
  "password_recovery": "Password Recovery",
  "whastmenu": "Whatsmenu",
  "start_date": "Start Date",
  "end_date": "End Date",
  "search": "Search",
  "print": "Print",
  "report": "Report",
  "product": "Product",
  "products": "Products",
  "product_m": "product",
  "qty_sold": "Qty. Sold",
  "total_amount": "Total Amount",
  "cash_summary": "CASH SUMMARY",
  "qty": "Qty.",
  "incomes": "Incomes",
  "table_closing": "Table closing",
  "table": "Table",
  "counter": "Counter",
  "request": "Request",
  "cancelled": "Cancelled",
  "cancelled_up": "CANCELLED",
  "cancelled_o": "Cancelled",
  "outflows": "Outflows",
  "phone_footer": "Phone",
  "n_orders": "No. of Orders",
  "language": "Language",
  "select_language": "Select the Language",
  "client": "Customer",
  "currency": "Currency",
  "brazil": "Brazil (R$)",
  "united_states": "United States ($)",
  "united_kingdom": "United Kingdom (£)",
  "portugal": "Portugal (€)",
  "country": "Country",
  "select_currency": "Select Currency",
  "portuguese_brazil": "Portuguese (Brazil)",
  "portuguese_portugal": "Portuguese (Portugal)",
  "english_us": "English (US)",
  "english_uk": "English (United Kingdom)",
  "english_ar": "English (Dubai)",
  "average_ticket": "Average Ticket",
  "total_spent": "Total Spent",
  "total_orders": "Total Orders",
  "total_amount_spent": "Total Amount Spent",
  "customer_report": "Customer Report",
  "number_of_orders": "No. of Orders",
  "order_history": "Order History",
  "order_code": "Order Code",
  "change_for": "Change for",
  "change": "Change",
  "payment": "Payment",
  "all_customers": "All Customers",
  "filter": "Filter",
  "order_volume": "Order Volume",
  "amount_consumed": "Amount Consumed",
  "no_results_found": "No results found",
  "no_customer_found": "No customer found with that name",
  "phone_number": "Phone number",
  "customer_found": "Customers Found",
  "opening_time_must_closing": "The opening time must be later than the closing time!",
  "time_will_not_take": "This time will not take effect on the",
  "following": "Following",
  "day": "Day",
  "changing_existing_time": "consider changing an existing time on the",
  "mentioned": "Mentioned",
  "please_select_day": "Please select a day!",
  "please_set_valid_time": "Please set a valid time!",
  "operating_hours": "Operating hours",
  "the_change_were": "The changes were not made because there are invalid times. Please correct the highlighted fields and try again.",
  "product_availability": "Product Availability",
  "setting_operating_hours": "Setting up operating hours",
  "choose_days_times": "CHOOSE THE DAYS AND TIMES",
  "select_all": "Select All",
  "from": "From",
  "until": "Until",
  "add": "Add",
  "open": "Open",
  "close": "Close",
  "actions": "Actions",
  "invalid_time": "Invalid time",
  "if_work_overnight": "If you also work overnight, meaning after midnight, register the hours like this",
  "pause": "Pause",
  "unpause": "Unpause",
  "delete": "Delete",
  "type": "Type",
  "priority": "Priority",
  "email_server": "Email Server",
  "content": "Content",
  "destination": "Destination",
  "address": "Address",
  "discount": "Discount",
  "surchage": "Surcharge",
  "fixed": "Fixed",
  "percentage": "Percentage",
  "type_of_fee": "Type of fee",
  "type_of_value": "Type of value",
  "fee_amount": "Fee amount",
  "copy": "COPY",
  "successfully_duplicated": "successfully duplicated",
  "not_possible_duplicate": "It was not possible to duplicate the category",
  "unable_duplicate_product": "Unable to duplicate the product.",
  "category": "Category",
  "successfully": "successfully",
  "the_category": "the category",
  "could_not": "Could not",
  "paused": "Paused",
  "unpaused": "Unpaused",
  "the_product": "the product",
  "sizes": "Sizes",
  "size": "Size",
  "edges_doughs": "Edges and Doughs",
  "flavors": "Flavors",
  "menu_information": "Menu Information",
  "cat_standard": "cat. Standard",
  "qty_product": "Qty. Products",
  "qty_add_ons": "Qty. Add-ons",
  "qty_size": "Qty. Sizes",
  "qty_edges_doughs": "Qty. Edges/Doughs",
  "qty_flavors": "Qty. Flavors",
  "you_edited": "You edited",
  "items": "items",
  "cancel_edit": "Do you want to cancel the edit",
  "there_are_no": "There are no",
  "to_update": "to update",
  "disconnect_account_asaas": "Disconnect Account Asaas",
  "package": "Packages",
  "appointments": "Appointments",
  "appointment": "Appointment",
  "share_facebook": "Share on Facebook",
  "copy_clipboard": "Copy to clipboard",
  "copied": "Copied",
  "link_sucess_copied": "Link successfully copied.",
  "unlink_link_copied": "Unable to copy the link, your browser does not support this feature.",
  "message_menu_adjust_prices": "The menu is your product showcase on WhatsMenu. Here, you can create categories, add items, extras, and options, set availability, and adjust prices.",
  "add_category": "Add Category",
  "reorder_menu": "Reorder Menu",
  "search_g": "Search",
  "search_place": "Search...",
  "complements": "Complements",
  "complement": "Complement",
  "pizza_flavors": "Pizza Flavors",
  "duplicate": "Duplicate",
  "edit": "Edit",
  "stock": "Stock",
  "low": "Low",
  "out_stock": "Out of stock",
  "edit_cover": "Edit Cover",
  "add_size": "Add Size",
  "add_item": "Add Item",
  "add_complements": "Add Complement",
  "edit_flavors": "Edit Flavors",
  "cancel_edits": "Cancel Edit",
  "prices": "Prices",
  "price": "Price",
  "photo": "Photo",
  "discard_changes": "Discard changes",
  "enter_valid_value": "Please enter a valid value!",
  "in_stock": "IN STOCK",
  "add_flavors_firt_pizza": "To add flavors, first create a pizza size.",
  "add_flavors": "Add Flavors",
  "updated_now": "Updated now",
  "flavors_will_be": "flavors will be",
  "flavor_will_be": "flavor will be",
  "please_wait": "Please wait...",
  "coin_t": "Coin",
  "please_wait_n": "Please wait",
  "edit_products": "Edit Products",
  "products_will_be": "products will be",
  "product_will_be": "product will be",
  "no_results_your_search": "No results for your search",
  "no_category_registred": "No category registered",
  "add_items_bulk": "Add items in bulk",
  "you_changing_promotional_price": "You are changing the promotional price of the product.",
  "system": "System",
  "cash_detail": "Cash Detail",
  "operator": "Operator",
  "image_preview": "Image Preview",
  "width": "Width",
  "height": "Height",
  "complete": "Complete",
  "review_entered_data": "Please review the entered data.",
  "confirm_financial_password": "Confirm Financial Password",
  "financial_password": "Financial Password",
  "how_to_make": "How to make",
  "updated": "updated",
  "updated_o": "updated",
  "created": "created",
  "unable_delete_category": "Unable to delete the category",
  "standard": "Standard",
  "delete_category": "Delete the category",
  "review_data": "Review the data",
  "details": "Details",
  "availability": "Availability",
  "select_category_model": "Select the category model",
  "acai_ice_creams": "Acai and Ice Creams",
  "burgers": "burgers",
  "meal_boxes": "Meal boxes",
  "meal_boxes_size_options": "Meal boxes with size options",
  "build_meal_box": "Build your own meal box",
  "butcher_shop": "Butcher shop",
  "produce_section": "Produce section",
  "drinks": "Drinks",
  "category_name": "Category name",
  "invalid_category_name": "Invalid category name",
  "characters": "characters",
  "minimum_stock": "Minimum stock",
  "add_availability_hours": "Add availability hours",
  "use_addons_another_item": "Use add-ons from another item",
  "creating_addons": "Creating add-ons",
  "shared_addons": "Shared add-ons",
  "attention": "Attention",
  "attention_up": "ATTENTION",
  "all_changes_addon_affect_other_product": "All changes made to this add-on will affect other products using it.",
  "link": "Link",
  "attention_addon_linked": "Attention this add-on is linked to",
  "attention_addon_linked_v2": "any changes made here will affect the linked products, except for deletions.",
  "other": "other",
  "view_linked_products": "View linked products",
  "invalid_name": "Invalid name",
  "min_greater_max": "Min greater than maximum",
  "max_less_min": "Max less than minimum",
  "mandatory_addon": "Mandatory add-on",
  "option": "Option",
  "please_enter_valid_value": "Please enter a valid value",
  "always_available": "Always available",
  "enable_stock": "Enable stock",
  "created_o": "created",
  "create": "Create",
  "was_deleted_successfully": "was deleted successfully",
  "could_not_delete_flavor": "Could not delete the flavor",
  "you_really_want_delete": "Do you really want to delete",
  "error": "error",
  "the_flavor": "the flavor",
  "flavor": "Flavor",
  "image_up_8": "Image up to 8MB",
  "add_image": "Add Image",
  "recomended_resolution_600": "recommended resolution of 600x450",
  "please_valid_name": "Please enter a valid name.",
  "message_pause_sales": "To pause sales of this item, click the button next to it. If the button is enabled, the item will not appear in your list of dishes.",
  "pause_sales": "Pause Sales",
  "resume_sales": "Resume Sales",
  "created_successfully": "created successfully.",
  "updated_successfully": "updated successfully.",
  "was_not_created": "was not created.",
  "was_not_updated": "was not updated.",
  "deleted_successfully": "deleted successfully.",
  "could_not_delete": "Could not delete.",
  "could_not_pause": "Could not pause",
  "resumed_successfully": "resumed successfully.",
  "paused_successfully": "paused successfully",
  "to_pause_sale_message": "To pause sales of this item, click the button next to it. If the button is enabled, the item will not appear in your list of dishes.",
  "size_name_already_exists": "A size with this name already exists.",
  "the_size": "the size",
  "update": "update",
  "message_delete_remove_another": "To delete, you need to remove the flavors or add another pizza size",
  "message_really_delete_size": "Do you really want to delete the size",
  "message_could_not_delete": "Could not delete the size",
  "edit_size": "Edit Size",
  "cover": "Cover",
  "this_size_accepts": "This size accepts",
  "add_cover": "Add Cover",
  "message_cannot_invalid_times": "Changes cannot be made because there are invalid times. Please correct the highlighted fields and try again",
  "review_not_allowed": "Review your add-ons; empty names are not allowed",
  "could_not_delete_product": "Could not delete the product",
  "delete_the_product": "Delete the product",
  "promotion": "Promotion",
  "activate_promotion": "Activate promotion",
  "button_enable_disable_promotion": "Use the button next to enable or disable the promotion.",
  "original_price": "Original price",
  "promotional_price": "Promotional price",
  "available_for": "Available for",
  "message_products_not_created": "The products were not created because there are invalid times. Please correct the highlighted fields and try again",
  "products_created_successfully": "All products were created successfully.",
  "products_not_created": "Products were not created.",
  "create_products_bulk": "Create Products in Bulk",
  "shortcuts": "Shortcuts",
  "right_arrow": "Right arrow",
  "left_arrow": "Left arrow",
  "next_step": "Next step",
  "back_one_step": "Go back one step",
  "create_products": "Create products",
  "add_product": "Add product",
  "duplicate_product": "Duplicate product",
  "product_name": "Product name",
  "table_price": "Table price",
  "product_description": "Product description",
  "duplicate_r": "Duplicate",
  "no_product_added": "No product added",
  "creating_products": "Creating products...",
  "reordered_successfully": "Reordered successfully",
  "could_not_reorder_items": "Could not reorder the items",
  "message_press_and_hold": "Press and hold for 1 second, then drag the items to the desired position. This sequence will also be reflected in your store.",
  "reordegring_wait": "Reordering, please wait...",
  "you_any_categories_yet": "You haven't registered any categories yet.",
  "please_review_data": "Please review the data.",
  "review": "Review",
  "fee_shipping": "Free Shipping",
  "fee_shipping_up": "FRETE GRÁTIS",
  "fee": "Fee",
  "disc": "Disc.",
  "free": "Free",
  "be_determined": "To be determined",
  "disabled": "Disabled",
  "order_slip": "Order slip",
  "order_slips": "Order slips",
  "ssn": "SSN",
  "duration": "Duration",
  "delivery_date": "Delivery date",
  "paid_up": "PAID",
  "coupon_used": "Coupon used",
  "delivery": "Delivery",
  "coupon": "Coupon",
  "payment_in": "Payment in",
  "total_paid": "Total Paid",
  "checkout": "Checkout",
  "paid_online_up": "PAID ONLINE",
  "delivery_person": "Delivery person",
  "s_n": "Y/N",
  "technology": "Technology",
  "missing": "Missing",
  "waiter": "Waiter",
  "paid": "Paid",
  "delivery_fee": "Delivery Fee",
  "printing_error": "Printing error",
  "could_not_send_printing": "Could not send the copy for printing.",
  "order_confirmation": "Order confirmation",
  "could_not_print_order": "Could not confirm the print of the order",
  "change_date": "Change date",
  "pending_prints": "Pending prints",
  "message_pending_print": "pending print orders. Do you want to print these orders?",
  "there_are": "There are",
  "print_all": "Print all",
  "could_not_play_sound": "Could not play the sound.",
  "printing_up": "PRINTING",
  "cancel_order": "Cancel order",
  "reinstate_order": "Reinstate order",
  "no_orders_print_up": "TTHERE ARE NO ORDERS TO PRINT",
  "order_preview": "Order preview",
  "teste_print_order": "Test print order",
  "report_request": "Report request",
  "received": "Received",
  "preparation": "Preparation",
  "delivering": "Delivering",
  "served": "Served",
  "ready_for_pickup": "Ready for pickup",
  "speak_customer": "Speak with customer",
  "select": "Select",
  "add_address": "Add address",
  "edit_address": "Edit address",
  "filling_establishment_address": "Filling in the establishment's address",
  "zip_code": "ZIP Code",
  "invalid_zip_code": "Invalid ZIP code",
  "number": "Number",
  "neighborhood": "Neighborhood",
  "city": "City",
  "enter_registed_email_recover_password": "Enter your registered email to recover your password",
  "please_enter_valid_email": "Please enter a valid email",
  "email_sent_successfully": "Email sent successfully.",
  "send": "Send",
  "delivery_package": "DELIVERY/PACKAGES",
  "change_order_date": "Change order date",
  "selected_date": "Selected date",
  "current_order_date": "Current order date",
  "distribution": "Distribution",
  "order_coder": "Order code",
  "no_order_selected": "No order selected",
  "go_back": "Go back",
  "pickup": "Pickup",
  "deliver": "Deliver",
  "no_items_this_date": "No items for this date",
  "all": "All",
  "pending": "Pending",
  "marked_received": "Marked as received",
  "local_delivery": "Local delivery",
  "summary_of": "Summary of",
  "your": "your",
  "your_a": "your",
  "filter_use": "Filter in use",
  "choose_date_summary": "Choose a date for the summary",
  "printing": "Printing",
  "selected_orders": "Selected orders",
  "regarding": "Regarding",
  "calendar_view": "Calendar view",
  "loading_orders": "Loading orders...",
  "order_slip_fees": "Order slip fees",
  "fees": "Fees",
  "charge": "Charge",
  "next_confirm": "Next/Confirm",
  "back_cancel": "Back/Cancel",
  "proceed": "Proceed",
  "delivery_report": "Delivery report",
  "qty_of_deliveries": "Qty. of deliveries",
  "total_delivery_fees": "Total delivery fees",
  "delivery_report_r": "Delivery report",
  "code_order": "Code Order",
  "date": "Date",
  "discard": "Discard",
  "pending_changes": "Pending changes",
  "you_whant_save_them": "There are unsaved changes. Do you want to save them?",
  "new": "New",
  "my_profile": "My profile",
  "delivery_drivers": "Delivery drivers",
  "tables": "Tables",
  "no_active_waiter": "No active waiter",
  "message_register_waitr_enable": "To register a waiter or enable an existing one, go to Settings > Table > in the Waiters tab, or click on",
  "register_waiter": "Register Waiter",
  "coupons": "Coupons",
  "social_media": "Social media",
  "reports": "Reports",
  "financial_report": "Financial report",
  "daily_orders": "Daily orders",
  "monthly_orders": "Monthly orders",
  "cash_register_closing": "Cash register closing",
  "best_sellers": "Best sellers",
  "customer_report_side": "Customer report",
  "general": "General",
  "cash_registers": "Cash registers",
  "domains": "Domains",
  "payment_methods": "Payment methods",
  "administrator": "Administrator",
  "support_bonus": "Support bonus",
  "salesperson_report": "Salesperson report",
  "annual_report": "Annual report",
  "updates": "Updates",
  "exit": "Exit",
  "support_hours": "From Mon to Sat",
  "settings": "Settings",
  "phone": "Phone",
  "press": "Press",
  "order_already_printed": "Order has already been printed",
  "order_not_printed": "Order not printed",
  "wait_order_printed": "Please wait for the order to be printed.",
  "displaying": "Displaying",
  "of": "of",
  "filter_o": "Filter",
  "caption": "Caption",
  "order_list": "Order list",
  "message_download_whatsmenu_desktop": "Download the NEW WhatsMenu Desktop Now",
  "message_less_work": "Less work and more sales",
  "message_virtual_assistant": "Virtual assistant for Phone number",
  "message_loyalty_program": "Loyalty program",
  "message_first_purchase": "First purchase coupon",
  "message_automated_sales_bot": "Automated sales bot",
  "message_status_updates": "Status updates without opening tabs",
  "message_download_app": "Download App",
  "donwload_the_whatsmenu": "Download the WhatsMenu Desktop",
  "message_download_whatsmenu_printers": "Download the new WhatsMenu Printers now",
  "message_automatic_printing_mobile": "Automatic printing on mobile",
  "message_printing_multiple_printers": "Printing on multiple printers",
  "message_copy_printing": "Copy printing",
  "message_download_gplay": "Download Via Google Play",
  "message_downlaod_whatsmenu_printers": "Download WhatsMenu Printers",
  "message_audio_disabled": "Audio disabled. Please interact with the screen to enable it.",
  "message_monthly_invoice_available": "Monthly invoice available for payment! Click here.",
  "message_menu_locked": "Your menu may be locked because your subscription is overdue! Click here.",
  "overview_pos_pizza_addons": "Overview POS and Pizza Add-ons WhatsMenu",
  "how_to_register_customer_pos": "How to register a customer in the POS",
  "guide_placing_order_pos": "Step-by-Step Guide to Placing an Order via POS",
  "how_to_update_customer_information_pos": "How to update customer information via POS",
  "guide_opening_cash_register": "Step-by-Step Guide to Opening the Cash Register",
  "guide_closin_cash_register": "Step-by-Step Guide to Closing the Cash Register",
  "cash_register_closing_reports": "Cash Register Closing Reports",
  "how_place_order_counter_pos": "How to Place a Takeout Order at the Counter via POS",
  "reordering_customers_orders": "Reordering Previous Customer Orders in a Few Clicks",
  "place_order_pizza_pos": "How to Place an Order for a Pizza with 2 or More Toppings, Crusts, and Add-ons via POS",
  "how_create_tables": "How to Create Tables",
  "guide_registering_pizza_crusts": "Step-by-Step Guide to Registering Pizzas with Crusts and Toppings",
  "pos": "POS",
  "internet_connection_lost": "Your internet connection has been lost. Please check your connection.",
  "internnet_connection_reestablished": "Your internet connection has been reestablished.",
  "register_your_customers_quickly_easily": "Register your customers and place orders more quickly and easily.",
  "repeat_your_customer_clicks": "Repeat your customer’s last order in just 2 clicks.",
  "cash_register_simply": "Opening and closing the cash register with simply fantastic reports.",
  "gain_extra_speed": "Gain extra speed in serving tables.",
  "support_more_information": "Your full plan subscription has expired, and we have not detected the payment. Please contact support for more information.",
  "dear_customer": "Dear Customer,",
  "view_menu": "View Menu",
  "have": "have",
  "not_have": "do not have",
  "new_orders": "new orders.",
  "go_to_orders": "Go to orders",
  "general_settings": "General Settings",
  "need_help": "Need Help?",
  "you": "You",
  "copied_clipboard": "Copied to clipboard",
  "copy_n": "Copy",
  "browser_not_have_copy": "Your browser does not have the copy option.",
  "cashback_status": "Cashback Status",
  "cashback_configuration": "Cashback Configuration",
  "loyalty": "Loyalty",
  "percentage_of": "Percentage",
  "percentage_up": "PERCENTAGE",
  "validity": "Validity",
  "days_n": "Days",
  "in_days": "In days",
  "how_to_configure_cashback": "How to Configure Cashback on WhatsMenu",
  "activate_cashback": "Activate Cashback",
  "promotion_link": "Promotion Link",
  "profile_information": "Profile Information",
  "store_name": "Store Name",
  "enter_store_name": "Enter the Store Name",
  "enter_slug": "Enter your slug",
  "contact_phone_number": "Contact Phone Number",
  "enter_contact_phone": "Enter the contact phone number",
  "message_phone_number_will": "This phone number will only be available to customers with ongoing orders.",
  "enter_store_description": "Enter your store description",
  "minimum_delivery_order": "Minimum Delivery Order",
  "minimum_order_excluding_delivery": "Minimum order value, excluding delivery fee.",
  "minimum_order_pickup": "Minimum Order for Pickup",
  "minimum_order_pickup_delivery": "Minimum order value for pickup, excluding delivery fee.",
  "preview_your_store": "Preview of your store",
  "preview": "Preview",
  "open_n": "Open",
  "calculate": "Calculate",
  "store_color": "Store Color",
  "wait_time": "Wait Time",
  "establishment": "Establishment",
  "store_layout": "Store Layout",
  "shipping": "Shipping",
  "congratulations": "Congratulations",
  "message_few_steps_organized": "In just a few steps, you'll have a fast and organized service with your",
  "digital_menu": "Digital Menu.",
  "basic_details_establishment": "Now fill in the basic details of your establishment.",
  "filling_profile": "Filling out profile",
  "restaurant_address": "Restaurant Address",
  "message_plan_not_include_delivery": "Your current plan does not include the Delivery or Orders/Scheduling functionality. Please contact support for more details.",
  "closing_scheduled_time": "Closing outside the scheduled time",
  "reopening_date": "Reopening Date",
  "time_zone": "Time Zone",
  "select_time_zone": "Select your time zone",
  "now": "Now",
  "add_business_hours": "Add Business Hours",
  "store": "Store",
  "closed": "Closed",
  "open_a": "open",
  "monday": "Monday",
  "tuesday": "Tuesday",
  "wednesday": "Wednesday",
  "thursday": "Thursday",
  "friday": "Friday",
  "saturday": "Saturday",
  "sunday": "Sunday",
  "delivery_fees": "Delivery Fees",
  "shipping_neighborhood": "Shipping by neighborhood",
  "shipping_by_mi": "Shipping by MI",
  "mile": "Mile",
  "delivery_type_change_to": "Delivery type changed to",
  "loading": "Loading...",
  "delivery_type": "Delivery Type",
  "by_mile": "By Mile",
  "reset_fees": "Reset Fees",
  "message_fee_deleted": "If you change the fee type, all current fees will be deleted",
  "message_really_change_fee": "Do you really want to change the fee type",
  "by_neighborhood": "By Neighborhood",
  "neighborhood_name": "Neighborhood Name",
  "neighborhood_cannot_duplicated": "The neighborhood cannot be duplicated",
  "to_be_confirmed": "To be confirmed",
  "neighborhood_added": "Neighborhood added",
  "city_and_neighborhood_added": "City and neighborhood added",
  "neighborhood_deleted": "Neighborhood deleted",
  "city_deleted": "City deleted",
  "time_minutes": "Time in Minutes",
  "changes_made_successfully": "Changes made successfully",
  "unexpected_try_again": "Something unexpected happened. Please try again.",
  "success": "Success",
  "online_payment_automatic": "Online Payment - Automatic",
  "message_avoid_fraud_errors": "Avoid fraud, errors, and delays in service by not having to verify receipts in the banking app.",
  "asaas_terms_use": "Asaas Bank Terms of Use",
  "acess_email": "Access the email",
  "message_open_asaas_email": "Open the Asaas email and click the link to create your banking password.",
  "message_sent_sms_token": "We have sent the SMS with the token to",
  "account_creation_details": "Account Creation Details",
  "branch": "Branch",
  "acess_asaas_account": "Access your Asaas account",
  "termis_of_use": "Terms of Use",
  "online_payment": "Online Payment",
  "payment_on_delivery_card": "Payment on Delivery - Card Reader",
  "payment_on_delivery": "Payment on Delivery",
  "automate_your_payments": "Automate the receipt of your payments",
  "activate_this": "Activate This",
  "notes": "Notes",
  "up_two_business_days": "Up to 2 business days",
  "acess_detailed_statements_asaas": "Note: Access your Asaas Bank app for detailed statements.",
  "enable_surcharge_discount": "Enable Surcharge/Discount",
  "disabled_o": "Disabled",
  "activated": "Activated",
  "card_network": "Card Network",
  "add_card_network": "Add Card Network",
  "key_type": "Key Type",
  "key": "Key",
  "invalid_pix": "Invalid Pix",
  "money": "Money",
  "pix": "Pix",
  "picpay": "PicPay",
  "credit_card": "Credit Card",
  "credit": "Credit",
  "debit": "Debit",
  "register_password": "Register Password",
  "meal_voucher": "Meal Voucher",
  "food_voucher": "Food Voucher",
  "month": "Month",
  "year": "Year",
  "january": "January",
  "february": "February",
  "march": "March",
  "april": "April",
  "may": "May",
  "june": "June",
  "july": "July",
  "august": "August",
  "september": "September",
  "october": "October",
  "november": "November",
  "december": "December",
  "search_for": "Search for",
  "summary": "Summary",
  "number_orders": "Number of Orders",
  "no_fees": "No Fees",
  "deliverys": "Deliverys",
  "number_deliveries": "Number of Deliveries",
  "total_sales_and_coupons": "Total sales for the period - Total coupons",
  "total_canceled": "Total Canceled",
  "number_canceled_orders": "Number of Canceled Orders",
  "total_fees": "Total Fees",
  "no_fees_period": "No fees for this period",
  "fixed_amount": "FIXED AMOUNT",
  "fixed_amount_n": "Fiex Amount",
  "payment_methods_n": "Payment Methods",
  "waitstaff": "Waitstaff",
  "creation_date": "Creation Date",
  "daily_report": "Daily Report",
  "monthly_report": "Monthly Report",
  "delivery_registration": "Delivery Personnel Registration",
  "error_deleting_delivery_person": "Error deleting delivery person",
  "error_pausing_activating": "Error pausing/activating delivery person",
  "error_editing_delivery_person": "Error editing delivery person",
  "delivery_person_name": "Delivery Person's Name",
  "enter_delivery_person": "Enter the delivery person's name",
  "enter_whatsapp_number": "Enter Phone number",
  "registered_delivery": "Registered Delivery Personnel",
  "fill_all_fields": "Please fill in all the fields.",
  "coupon_management": "Coupon Management",
  "coupon_code": "Coupon Code",
  "coupon_already_exists": "This coupon already exists",
  "offer_type": "Offer Type",
  "coupon_type": "Coupon Type",
  "first_purchase": "First Purchase",
  "discount_amount": "Discount Amount",
  "minimum_amount": "Minimum Amount",
  "code": "Code",
  "unable_create_the_coupon": "Unable to create the coupon",
  "coupon_not_created": "Coupon not created.",
  "status_coupon": "Coupon Status",
  "actives": "actives",
  "for_orders": "for orders",
  "inactive": "inactive",
  "successfully_deleted": "successfully deleted",
  "delete_coupon": "Delete Coupon",
  "business_offer_deliveries": "Does your business offer deliveries",
  "customer_pick_up_order_onsite": "The customer can pick up the order on-site",
  "cash_register": "Cash Register",
  "active_cash_management": "Activate cash register management",
  "message_currently_open_cash": "You currently have open cash registers. Please close them by",
  "before_register_management": "before changing the cash register management",
  "customers": "Customers",
  "display_date_birth": "Display Date of Birth",
  "require_date_birth": "Require Date of Birth",
  "default_texts": "Default Texts",
  "changing_default_text_observations": "Changing default text for observations",
  "product_note": "Product Note",
  "example_no_mayonnaise": "E.g., No mayonnaise, no salad, etc.",
  "pizza_note": "Pizza Note",
  "leave_any_note_product_here": "Leave any note about the product here.",
  "message_client": "Hello [NOME], how are you?",
  "status_received": "Status Received",
  "you_order_production": "[NOME] Your order is already in production!",
  "status_delivering": "Status Delivering",
  "yay_order_already_way": "Yay [NOME], your order is already on its way!",
  "status_pickup": "Status Pickup",
  "order_ready_pickup": "Yay, [NOME], your order is ready for pickup!",
  "order_status_pos": "Order Status in POS",
  "order_successfully_placed": "[NOME], your order was successfully placed. Track your order status below!",
  "greeting_message": "Greeting Message",
  "message_hello": "Hello, [NOME]!",
  "welcome_to": "Welcome to",
  "check_menu_order": "Check out our menu to place your order",
  "exclusive_offers": "Exclusive offers for orders through the link",
  "team": "Team",
  "use_a_printer": "Use a printer",
  "group_order_printing": "Group order items for printing",
  "print_test": "Print Test",
  "test_print": "Test Print",
  "printer_driver": "Printer Driver",
  "others": "Others",
  "printing_app": "Printing App",
  "print_width": "Print Width",
  "letters": "Letters",
  "small": "Small",
  "large": "Large",
  "copies": "Copies",
  "necessary_whatsmenu_android_installed": "It is necessary to have the Whatsmenu Printer 2.0 app installed (Android only).",
  "pizza_options": "Pizza Options",
  "assign_price_most_expensive": "Assign the price of the most expensive",
  "different_crusts_each_flavor": "Allow different crusts for each flavor",
  "allow_each_flavor": "Allow toppings for each flavor",
  "stock_options": "Stock Options",
  "display_out_of_stock_product_addons": "Display 'out of stock' status for paused products and add-ons?",
  "hide_ssn_receipt": "Hide SSN option on the receipt",
  "description_top_product_page": "Display product photo and description at the top of the product page",
  "activate_catalog_mode_delivery": "Activate Catalog Mode in Delivery",
  "activate_catalog_the_table": "Activate Catalog Mode at the Table",
  "automations": "Automations",
  "send_status_page_pos_customer": "Do you want to send the status page on the POS to the customer",
  "only_indentification": "Only the identification",
  "label": "Label",
  "saving": "Saving...",
  "are_no_pending_changes": "There are no pending changes",
  "no_changes": "No changes",
  "saved": "Saved",
  "saved_successfully": "Saved successfully.",
  "order_date": "Order Date",
  "tomorrow": "Tomorrow",
  "today": "Today",
  "deliver_pickup": "Delivering/Pickup",
  "delivery_e": "Delivery",
  "closed_o": "Closed",
  "receive_orders_from": "To receive orders from",
  "enable_options_the_settings_for": "enable the options in the settings for",
  "printed": "Printed",
  "are_no_orders_moment": "There are no orders at the moment",
  "hours": "Hours",
  "review_delivery_hours": "Please review the delivery hours.",
  "changes_saved": "Changes saved successfully.",
  "not_saved": "Not Saved",
  "unable_save_check_connection": "Unable to save changes, please check your connection.",
  "no_changes_to_save": "There are no changes to save.",
  "delivery_type_activated": "At least one delivery type must be activated",
  "pickups": "Pickups",
  "deliveries_pickups": "Deliveries/Pickups",
  "delivery_types_must_one_activated": "Delivery types must have at least one activated",
  "activate_orders": "Activate Orders",
  "delivery_scheduling": "Delivery Scheduling",
  "order_settings": "Order Settings",
  "pickup_onsite": "Pickup On-Site",
  "allow_onesite_pickups": "Allow On-Site Pickups",
  "allow_delivery_services": "Allow Delivery Services",
  "make_available": "Make Available",
  "starting_from": "starting from",
  "allow": "Allow",
  "for_day": "for the day",
  "within": "within",
  "group": "Group",
  "every": "every",
  "minutes": "Minutes",
  "max_of": "Max. of",
  "per_day_from": "per day from 1 to 1000",
  "per_hour": "per hour",
  "display_menu_as": "Display on the menu as",
  "minimum_for_delivery": "Minimum for delivery",
  "minimum_for_pickup": "Minimum for pickup",
  "register_order_cash_register": "Register Orders at the Cash Register",
  "on_day_ordered": "On the day it was ordered",
  "on_delivery_day": "On the delivery day",
  "availability_of": "Availability of",
  "dates_not_be_available": "Dates you will not be available",
  "select_special_dates": "Select the special dates you will not be available",
  "select_a_date": "Select a date",
  "defined_dates": "Defined Dates",
  "remove": "Remove",
  "values": "Values",
  "minimum_order_for_pickup": "Minimum Order for Pickup",
  "table_name_already_exists": "A table with this name already exists",
  "create_table": "Create Table",
  "enter_valid_name": "Please enter a valid name",
  "new_table": "New Table",
  "creating_table": "Creating table",
  "using_table_system": "Using table system",
  "time_zone_successfully_changed": "Time zone successfully changed",
  "table_name": "Table Name",
  "cancel": "Cancel",
  "available": "Available",
  "occupied": "Occupied",
  "paused_a": "Paused",
  "selected": "Selected",
  "you_haven_registered_tables": "You haven't registered any tables yet",
  "how_close_entire_table": "How to close the entire table",
  "how_close_individual": "How to close individual tabs",
  "table_settings": "Table Settings",
  "fee_name_already_exists": "A fee with this name already exists.",
  "fee_name_cannot_empty": "Fee name cannot be empty.",
  "enter_value_greater_than": "Enter a value greater than",
  "add_fee": "Add Fee",
  "created_successfully_a": "created successfully",
  "activate_fee": "Activate Fee",
  "automatic_charge": "Automatic Charge",
  "changed_successfully": "changed successfully",
  "delete_fee": "Delete Fee",
  "deleted_successfuly": "deleted successfully",
  "register_waitstaff": "Register Waitstaff",
  "update_waitstaff": "Update Waitstaff",
  "create_waitstaff": "Create Waitstaff",
  "edit_waitstaff": "Edit Waitstaff",
  "delete_waitstaff": "Delete Waitstaff",
  "deleted_successfully_o": "deleted successfully",
  "restrict_menu": "Restrict Menu",
  "unable_retrieve_menu_information": "Unable to retrieve menu information at the moment, please try again",
  "creating_fees": "Creating fees",
  "fee_name": "Fee Name",
  "fee_already_exists": "This fee already exists",
  "type_charge": "Type of Charge",
  "enter_number_greater_than": "Enter a number greater than",
  "charge_automatically": "Charge Automatically",
  "deactive": "Deactivate",
  "registering_waitstaff": "Registering Waitstaff",
  "placing_order_waiter": "Placing an order as a waiter",
  "placing_pizza_order_waiter": "Placing a pizza order as a waiter",
  "waitstaff_name": "Waitstaff Name",
  "permission": "Permission",
  "manager": "Manager",
  "allow_orders": "Allow Orders",
  "register": "Register",
  "categories": "Categories",
  "select_categories_waiter_permitted": "Select which categories the waiter will be permitted to order from",
  "call_waitstaff": "Call Waitstaff",
  "keep_waitstaff_logged": "Keep waitstaff logged in",
  "change_password": "Change password",
  "change_table": "Change Table",
  "enter_valid_name_different_current": "Please enter a valid name or a name different from the current one",
  "delete_table": "Delete Table",
  "activated_a": "activated",
  "pause_table": "Pause Table",
  "not_possible_pause_table_open": "It is not possible to pause tables with open tabs",
  "table_options_up": "TABLE OPTIONS",
  "points": "Points",
  "settings_up": "SETTINGS",
  "background": "Background",
  "resources": "Resources",
  "register_cashier": "Register Cashier",
  "registering_operator": "Registering Operator",
  "placing_order_operator": "Placing an order as an operator",
  "operator_name": "Operator Name",
  "operators": "Operators",
  "box_settings": "Box Settings",
  "delete_operator": "Delete Operator",
  "edit_operator": "Edit Operator",
  "create_operator": "Create Operator",
  "update_operator": "Update Operator",
  "register_operator": "Register Operator",
  "there_failure_transaction": "There was a failure in the transaction",
  "change_card": "Change Card",
  "unable_change_payment_card": "Unable to change the payment card.",
  "redirecting_payment_page": "Redirecting to the Payment Page.",
  "difficulty_generating_payment_screen": "We’re having difficulty generating the payment screen. Please try again.",
  "payment_gateway": "Payment Gateway",
  "no_payment_gateway_found": "No payment gateway was found",
  "subscription": "Subscription",
  "adding_your_subscription": "We are adding your subscription.",
  "failure_adding_your_subscription": "There was a failure in adding your subscription",
  "subscription_created_successfully": "Your subscription has been created successfully",
  "failure_processing_try_again": "There was a failure in processing. Please try again",
  "ongoing_monthly_invoice": "There is an ongoing monthly invoice.",
  "subscription_canceled": "Your subscription is canceled",
  "required": "Required",
  "additional": "Additional",
  "monthly": "Monthly",
  "annual": "Annual",
  "semiannual": "Semi-Annual",
  "monthly_payment": "Monthly payment by card",
  "add_registration_service": "Add registration service",
  "with_printer": "With printer",
  "select_option": "Select an option",
  "start_registration": "Start Registration",
  "proceeding_whatsmenu_contact": "By proceeding, I agree that WhatsMenu may contact me by phone, email, or WhatsApp (including automated messages for commercial purposes).",
  "message_failure_payment_card": "There was a failure in processing the payment. Please check the details and ensure there is available credit on your card",
  "owner_name": "Owner's Name",
  "payment_completed_successfully": "Payment completed successfully. We will reload the page.",
  "outstanding_invoices": "Outstanding Invoices",
  "in_progress": "In Progress",
  "pay": "Pay",
  "view_items": "View Items",
  "cart": "Cart",
  "check_items": "Check the items",
  "there_no_items_moment": "There are no items at the moment",
  "unique": "Unique",
  "hello": "Hello",
  "after_payment_confirmation": "after payment confirmation, you will be redirected and the page will be reloaded.",
  "proceed_payment": "Proceed to Payment",
  "pending_pay": "Pending",
  "refunded": "Refunded",
  "under_review": "Under Review",
  "awaiting_confirmation": "Awaiting Confirmation",
  "your_monthly_every_day": "Your monthly fee is due every day.",
  "each_month": "each month",
  "services": "Services",
  "plan": "Plan",
  "generate_invoice": "Generate Invoice",
  "no_outstandigin_invoices_moment": "There are no outstanding invoices at the moment.",
  "paid_invoice_history": "Paid Invoice History",
  "detail_table": "Detail Table",
  "order_results": "Order Results",
  "displaying_orders_date_from": "Displaying orders by date from",
  "domain_registered_dns_registration": "Domain registered successfully! Please contact support to request DNS registration.",
  "domain_registered": "Domain registered successfully!",
  "domain_already_registered": "This domain is already registered",
  "enter_valid_domain": "Please enter a valid domain",
  "invalid_domain": "Invalid Domain",
  "domain_not_valid": "This domain is not valid. Please enter a valid domain",
  "domain_successfully_deleted": "Domain successfully deleted",
  "delete_domain": "Delete Domain",
  "default_domain_successfully_changed": "Default domain successfully changed",
  "dns_entry": "DNS Entry",
  "dns_entry_successfully": "DNS entry successfully deleted",
  "domain_settings": "Domain Settings",
  "after_registering_domain": "After registering the domain, you will need to point it to these servers",
  "domain": "Domain",
  "domain_information": "Domain Information",
  "registered_domains": "Registered Domains",
  "default_domain_cannot_deleted": "The default domain cannot be deleted. Please contact support",
  "domain_com": "domain.com",
  "default_domain": "Default Domain",
  "monthly_orders_report": "Monthly Orders Report",
  "daily_orders_report": "Daily Orders Report",
  "box_closures": "Box Closures",
  "box_report": "Box Report",
  "customer_report_v2": "Customer Report",
  "best_selling_report": "Best-Selling Report",
  "best_sellers_v2": "Best Sellers",
  "click_here_view_details": "Click here to view the details",
  "summary_last_months": "Summary of the last 12 months",
  "summary_last_days": "Summary of the last 30 days",
  "financial": "Financial",
  "ph": "Phone",
  "dailys": "Daily",
  "monthlys": "Monthly",
  "opening": "Opening",
  "export_spreadsheet": "Export to spreadsheet",
  "creation": "Creation",
  "period": "Period",
  "delivery_m": "delivery",
  "ready": "Ready",
  "spreadsheet": "Spreadsheet",
  "successfully_generated": "successfully generated",
  "no_orders_found": "No orders found",
  "empty": "Empty",
  "outputs": "Outputs",
  "daily": "Daily",
  "operators_boxes": "Operators Boxes",
  "box_listing": "Box Listing",
  "opening_date": "Opening Date",
  "closing_date": "Closing Date",
  "box_closure": "Box Closure",
  "paid_a": "Paid",
  "food": "Food Voucher",
  "snack": "Meal Voucher",
  "order_printed_please_wait_finish": "An order is being printed, please wait for it to finish",
  "password_settings": "Password Settings",
  "access": "access",
  "security": "security",
  "incorrect_current_password": "Incorrect current password",
  "register_financial_password": "Register a financial password",
  "change_financial_password": "Change Financial Password",
  "register_password_financial": "Register Financial Password",
  "current_password": "Current Password",
  "new_password": "New Password",
  "confirm_password": "Confirm Password",
  "change_access_password": "Change Access Password",
  "recover_financial_password": "Recover Financial Password",
  "confirm_new_password": "Confirm New Password",
  "authentication_failure": "Authentication Failure",
  "password_recovery_token": "Password recovery token expired or invalid",
  "use_printer": "Use Printer",
  "enable_stock_control": "Enable Stock Control",
  "stock_control": "Stock Control",
  "layout_options": "Layout Options",
  "security_password_registered": "Security password registered successfully",
  "my_name_is": "My name is",
  "no_time_up": "NO TIME",
  "with": "with",
  "consult": "Consult",
  "link_for_order_status": "Link for order status",
  "chosen_date_earlier_current_date": "The chosen date is earlier than the current date. Please select a later date.",
  "check_the_cvv": "Check the CVV",
  "check_card_details": "Check the card details",
  "check_billing_address": "Check the billing address",
  "check_card_expiration_date": "Check the card's expiration date",
  "check_card_number": "Check the card number",
  "check_security_code": "Check the security code",
  "check_zip_code": "Check the ZIP code",
  "unable_register_your_order_try_again": "Unable to register your order. Please try again.",
  "system_identified_you_are_outside": "The system has identified that you are outside our coverage area. Please try to complete your order again.",
  "swiss_french": "Swiss French (Fr.)",
  "swiss_french_chf": "Swiss French ",
  "set_the_environment": "Set the Environment",
  "marketing_and_sales": "Marketing and Sales",
  "we_integrate_your_store": "We integrate your store with the biggest marketing and sales tools on the market, attract more customers and sell much more with less work.",
  "own_website": "Own Website",
  "disponibility": "Disponibility",
  "please_enter_valid_name": "Please enter a valid name!",
  "image_up": "Image up to 8mb (recommended resolution of 600x450)",
  "share": "Share",
  "fill_all_required": "Fill in all required fields",
  "billing": "Billing",
  "result_orders": "Result Orders",
  "viewing_orders_by_date": "Viewing orders by date",
  "enable_original_image_size": "Enable original image size",
  "cashback": "Cashback",
  "no_register": "No registration",
  "load_complements_wait": "Loading complements, please wait",
  "deactivated_delivery": "Disable Delivery",
  "hide_unity": "Hidden Unity",
  "Profile_options": "Profile Options",
  "Delivery_options": "Delivery Options",
  "status_order": "order status",
  "completed": "completed",
  "slug": "Slug",
  "clone_categories": "Clone Categories",
  "clone": "Clone",
  "no_change_needed": "No change needed",
  "serivce_fee": "Service Fee",
  "customer_delivery_address": "Customer Delivery Address",
  "route_up": "ROUTE",
  "payments_in": "Payments in",
  "not_informed": "Not informed",
  "the_order_is_now_ready": "the order is now ready for collection! Here is the customer's contact information",
  "if_you_need": "if you need",
  "reference": "Reference",
  "pickup_the_location": "Pickup the location",
  "table_request": "Table request",
  "possible": "Possible",
  "the_date_of_your_delivery": "The date of your delivery is",
  "payment_debit_card": "debit card payment",
  "payment_credit_card": "credit card payment",
  "zip_invalid": "Invalid ZIP code",
  "enter your cpf": "Enter your CPF",
  "n": "No.",
  "cash_outflows": "outflows",
  "cod": "code",
  "out for delivery": "out for delivery",
  "add_another_card": "add another card",
  "mbway": "MBWay",
  "spin": "Spin",
  "origin": "Origin",
  "not_received": "Not received",
  "catalog_mode": "Catalog Mode",
  "sounds_notification": "Order notification sound",
  "play_sound_delivery": "Enable delivery order sound",
  "play_sound_table": "Enable table order sound",
  "play_sound_package": "Enable package order sound",
  "showLocale": true,
  "additionals": "Additionals",
  "order_cod": "Order Code",
  "cancel_edit_message": "Do you want to cancel the edit?",
  "options": "Options",
  "closed_a": "closed",
  "opening_balance": "Opening balance",
  "tax_settings": "NFe Settings",
  "edit_company": "Edit Company",
  "add_company": "Add Company",
  "configure_emission": "Configure Issuance",
  "company": "Company",
  "natural_person": "Natural Person",
  "company_name": "Company Name",
  "certificate": "e-CNPJ A1",
  "attach_certificate": "Attach A1 Model Certificate",
  "identification": "Identification",
  "responsable": "Responsible",
  "accounting": "Accounting",
  "tokens": "Tokens",
  "tax_documents": "Tax Documents",
  "attach_company_logo": "Attach company logo",
  "trade_name": "Trade Name",
  "state_registration": "State Registration",
  "municipal_registration": "Municipal Registration",
  "tax_regime": "Tax Regime",
  "select_an_option": "Select an option",
  "simple_national": "Simple National",
  "Excess_gross_revenue_sublimit": "Excess gross revenue sublimit",
  "normal_regime": "Normal Regime",
  "email": "Email",
  "ein": "EIN",
  "municipality": "Municipality",
  "fu": "State",
  "responsible_name": "Responsible Person Name",
  "responsible_ssn": "Responsible Person CPF",
  "approval_token": "Approval Token",
  "production_token": "Production Token",
  "approval": "Approval",
  "series": "Series",
  "next_number": "Next number",
  "id_approval_token": "Approval Token ID",
  "security_code_approval": "CSC Approval",
  "production": "Production",
  "id_production_token": "Production Token ID",
  "security_code_production": "CSC",
  "advanced_settings": "Advanced Settings",
  "electronic_consumer_fiscal_note": "NFCe",
  "enable_offline_contingency": "Enable offline contingency",
  "all_documents": "All Documents",
  "send_email_to_recipient": "Send email to recipient",
  "discriminate_taxes": "Discriminate Taxes",
  "electronic_fiscal_note": "NFe",
  "show": "Show",
  "auxiliary_document_for_the_electronic_fiscal_notes": "DANFSe",
  "show_badge_focus_efn_on_adefn": "Show Focus NFe badge on DANFSe",
  "emission_ecfn": "NFCe Issuance",
  "whenever_necessary_you_can_manually_issue_in_addition_to_the_defined_standard": "Whenever necessary, you can manually issue in addition to the defined standard",
  "set_defaults_for_automatic_issuance_of_your_fiscal_notes": "Set defaults for automatic issuance of your fiscal notes",
  "daily_average": "Daily Average",
  "credit_machine": "Credit Machine",
  "credit_online": "Online Credit",
  "debit_machine": "Debit Machine",
  "debit_online": "Online Debit",
  "dont_miss_deadlines": "Don't miss deadlines",
  "we_will_send_the_fiscal_closing": "We will send the monthly fiscal closing (XML) to your accounting",
  "accountant_email": "Accountant Email",
  "additional_service": "Additional Service",
  "gratis": "Free",
  "for_shopkeeper": "for shopkeeper",
  "WM": "WhatsMenu",
  "plan_change": "Plan Change",
  "current_plan": "Current Plan",
  "plan_expiration": "Plan Expiration",
  "change_plan": "Change Plan",
  "integrations": "Integrations",
  "online_payment_s": "Online Payments",
  "online_payment_description": "Avoid fraud, errors, and delays by having to verify receipts in the banking app. The order arrives on the panel, and the payment is securely processed directly into your account.",
  "fiscal_note_issuer": "NFe and NFCe Issuer",
  "fiscal_note_issuer_description": "Stop wasting time manually entering invoices. Define automations and set limits according to your company's EIN classification.",
  "grove_nfe_description": "Automatic Issuance and Management of Tax Invoices",
  "automation_of_nfe": "NFC-e Automation",
  "certificate_password": "Your A1 Model Certificate Password",
  "unable_to_read": "Unable to read your location. Please check if location services are not blocked.",
  "address_covarage_area": "Your address is outside our coverage area!",
  "register_open_24": "A register has been open for more than 24 hours and will be closed.",
  "table_desactived": "Sorry, this table is currently disabled.",
  "moment_table_not_avaible": "At the moment, this table is not available for new orders.",
  "table_not_avaible_new_orders": "At the moment, this table is not available for new orders.",
  "order_already_been_completed": "The previous order has already been completed. Are you still at the restaurant, or would you like to place a new order for delivery?",
  "at_the_table": "I'm at the table",
  "deliver_order": "Deliver order",
  "not_belong_this_table": "This order does not belong to this table.",
  "store_closed": "STORE CLOSED!",
  "not_minimum_value": "Your order has not reached the minimum value of",
  "failed_validate_coupon": "Failed to validate the coupon. Please check your connection!",
  "amount_not_include_delivery_fee": "This total amount does not include the delivery fee.",
  "coupon_used_starting_from": "This coupon can only be used for purchases starting from",
  "closed_delivery": "CLOSED FOR DELIVERY",
  "unable_create_customer": "Unable to create customer. Please try again.",
  "selected_date_earlier_choose_later_date": "The selected date is earlier than the current date. Please choose a later date.",
  "try_again_moment": "Something unexpected happened. Please try again in a moment.",
  "error_generate_qrcode": "Unable to generate the QR Code. Please try again.",
  "payment_not_detected": "Payment not detected. Please check your banking app.",
  "reloaded_page_message": "The page needs to be reloaded!",
  "name_and_phone_required": "Name and phone number are required.",
  "enter_your_details": "Enter your details",
  "enter_your_name": "Enter your name",
  "enter_your_whatsapp": "Enter your WhatsApp",
  "enter_your_date_of_birth": "Enter your date of birth",
  "review_change_amount": "Review the Change Amount!",
  "no_internet_connection": "No Internet connection",

  "whatsMenuPro": {
    "this_app_is_better_than": "This APP is better than employees whether in-store or for delivery, all management is now automated!",
    "waiter_app_delivery": "WAITER APP, DELIVERY ROUTE, AND MUCH MORE.",
    "yes_i_want": "YES, I WANT MORE INFORMATION",
    "i_want_information": "I WANT INFORMATION!",
    "aprroved_by": "Approved by over",
    "restaurants_worldwide": "restaurants worldwide",
    "more_professionalism": "More professionalism",
    "more_clients_sales": "More clients and more sales...",
    "whatsMenuPro_transform_small": "WhatsMenu.PRO transforms small restaurants into powerful sales machines:",
    "receive_orders_auto": "Receive orders automatically without interacting with customers.",
    "customers_will_not": "Customers will no longer have to wait for the attendant.",
    "no_extra_labor": "No extra labor required to take orders.",
    "automatic_priting": "Automatic printing of orders.",
    "never_lose_order": "Never lose an order again.",
    "reduce_to_zero": "Reduce order errors to zero.",
    "increase_the_average": "Increase the average ticket and purchase frequency.",
    "control_everything": "Control everything automatically on your phone or computer.",
    "the_features": "The features you'll receive.",
    "the_most_complet": "A mais completa e poderosa Plataforma de Restaurantes:",
    "virtual_attendant": "Virtual Attendant",
    "orders_delivery_scheduling": "Orders, Delivery Scheduling",
    "counter_orders": "Counter orders",
    "order_manager": "Order Manager",
    "pos_point_of_sale": "POS - Point of Sale",
    "reports_customers": "Reports: Customers, Products, Revenue, Inventory",
    "qr_code_table_delivery": "QR Code for Table, Delivery, and Counter",
    "free_website": "Free Professional Website",
    "app_waiter": "APP Waiter",
    "delivery_manager": "Delivery manager",
    "automatic_inventory_control": "Automatic Inventory Control",
    "domain_hosting": "Domain hosting",
    "talk_to_attendant": "TALK TO THE ATTENDANT",
    "those_who_use": "Those who use it recommend",
    "see_the_models": "See the models and place",
    "test_order": "a test oder.",
    "talk_with_attendant": "Talk with the attendant",
    "still_afraid": "But I'm still afraid",
    "wont_work": "it won't work, what now?",
    "we_have_human_team": "We have a 100% human team, highly trained to help you with these first steps, and whenever you need. We will schedule an onboarding video call and assist you with the settings. We'll guide you every step of the way!",
    "yes_i_wanna_help": "YES, I WANT HELP",
    "days_of_satisfaction": "7 days of satisfaction",
    "or_your_money_back": "or your money back!",
    "i_belive_in_the_results": "I believe so much in the results that the WhatsMenu.PRO platform will bring you that I offer a 7-day guarantee for you to try it out. If you're not satisfied with the results or if you regret it for any other reason, just contact me on WhatsApp, and I'll refund 100% of your money.",
    "talk_with_us_on_WhatsApp": "Talk with us on WhatsApp",
    "what_is_your_name": "What is your name?",
    "what_is_your_WhatsApp": "What is your WhatsApp?",
    "what_product_you_sell": "What product do you sell?",
    "Browse": "Navegue",
    "how_works": "HOW IT WORKS",
    "features": "FEATURES",
    "clients": "CLIENTS",
    "examples": "EXAMPLES",
    "team": "TEAM",
    "support": "SUPPORT",
    "access_dashboard": "ACCESS DASHBOARD",
    "right_reserved": "All rights reserved.",
    "digital_menu": "DIGITAL MENU",
    "delivery": "DELIVERY",
    "call": "CALL NOW!",
    "sounds_notification": "Order notification sound",
    "play_sound_delivery": "Enable delivery sound",
    "play_sound_table": "Enable table order sound",
    "play_sound_package": "Enable scheduling sound",
    "payment_debit_card": "payment by debit card",
    "payment_credit_card": "payment by credit card",
    "zip_invalid": "Invalid ZIP code",
    "enter your cpf": "Enter your CPF",
    "n": "No.",
    "cash_outflows": "outflows",
    "cod": "code",
    "out for delivery": "out for delivery",
    "add_another_card": "add another card",
    "mbway": "MBWay",
    "spin": "Spin",
    "hide_unity": "Disable quantity",
    "Delivery_options": "Delivery Options",
    "Profile_options": "Profile Options",
    "catalog_mode": "Catalog Mode",
    "showLocale": true,
    "additionals": "Additionals",
    "order_cod": "Order Code",
    "cancel_edit_message": "Do you want to cancel the edit?",
    "options": "Options",
    "closed_a": "closed",
    "opening_balance": "Opening balance",
    "tax_settings": "NFe Settings",
    "edit_company": "Edit Company",
    "add_company": "Add Company",
    "configure_emission": "Configure Emission",
    "company": "Company",
    "natural_person": "Natural Person",
    "company_name": "Corporate Name",
    "certificate": "e-CNPJ A1",
    "attach_certificate": "Attach A1 Model Certificate",
    "identification": "Identification",
    "responsable": "Responsible",
    "accounting": "Accounting",
    "tokens": "tokens",
    "tax_documents": "Tax Documents",
    "attach_company_logo": "Attach company logo",
    "trade_name": "Trade Name",
    "state_registration": "State Registration",
    "municipal_registration": "Municipal Registration",
    "tax_regime": "Tax Regime",
    "select_an_option": "Select an option",
    "simple_national": "Simple National",
    "Excess_gross_revenue_sublimit": "Excess gross revenue sublimit",
    "normal_regime": "Normal Regime",
    "email": "Email",
    "ein": "EIN",
    "municipality": "Municipality",
    "fu": "State",
    "responsible_name": "Responsible Name",
    "responsible_ssn": "Responsible CPF",
    "approval_token": "Approval Token",
    "production_token": "Production Token",
    "approval": "Approval",
    "series": "Series",
    "next_number": "Next number",
    "id_approval_token": "Approval Token ID",
    "security_code_approval": "Approval CSC",
    "production": "Production",
    "id_production_token": "Token ID",
    "security_code_production": "CSC",
    "advanced_settings": "Advanced Settings",
    "electronic_consumer_fiscal_note": "NFCe",
    "enable_offline_contingency": "Enable offline contingency",
    "all_documents": "All Documents",
    "send_email_to_recipient": "Send email to recipient",
    "discriminate_taxes": "Discriminate Taxes",
    "electronic_fiscal_note": "NFe",
    "show": "Show",
    "auxiliary_document_for_the_electronic_fiscal_notes": "DANFSe",
    "show_badge_focus_efn_on_adefn": "Show Focus NFe badge on DANFSe",
    "emission_ecfn": "NFCe Emission",
    "whenever_necessary_you_can_manually_issue_in_addition_to_the_defined_standard": "Whenever necessary, you can manually issue in addition to the defined standard",
    "set_defaults_for_automatic_issuance_of_your_fiscal_notes": "Set defaults for automatic issuance of your fiscal notes",
    "daily_average": "Daily Average",
    "credit_machine": "Credit Machine",
    "debit_machine": "Debit Machine",
    "debit_online": "Online Debit",
    "dont_miss_deadlines": "Don't miss deadlines",
    "we_will_send_the_fiscal_closing": "We will send the monthly fiscal closing (XML) to your accountant",
    "accountant_email": "Accountant's Email",
    "additional_service": "Additional Service",
    "gratis": "Free",
    "for_shopkeeper": "for shopkeeper",
    "WM": "WhatsMenu",
    "plan_change": "Plan Change",
    "current_plan": "Current Plan",
    "plan_expiration": "Plan Expiration",
    "change_plan": "Change Plan",
    "certificate_password": "Your A1 Model Certificate Password",
    "unable_to_read": "Unable to read your location, please check if location services are not blocked.",
    "address_covarage_area": "Your address is outside our coverage area!",
    "register_open_24": "A register has been open for more than 24 hours and will be closed.",
    "table_desactived": "Sorry, this table is currently disabled.",
    "moment_table_not_avaible": "At the moment, this table is not available for new orders.",
    "table_not_avaible_new_orders": "At the moment, this table is not available for new orders.",
    "order_already_been_completed": "The previous order has already been completed. Are you still at the restaurant or would you like to place a new order for delivery?",
    "at_the_table": "I'm at the table",
    "deliver_order": "Deliver order",
    "not_belong_this_table": "This order does not belong to this table.",
    "store_closed": "STORE CLOSED!",
    "not_minimum_value": "Your order has not reached the minimum amount of",
    "failed_validate_coupon": "Failed to validate the coupon, please check your connection!",
    "amount_not_include_delivery_fee": "This total amount does not include the delivery fee.",
    "coupon_used_starting_from": "This coupon can only be used for purchases starting from",
    "closed_delivery": "CLOSED FOR DELIVERY",
    "unable_create_customer": "Unable to create the customer. Please try again.",
    "selected_date_earlier_choose_later_date": "The selected date is earlier than the current date, please choose a later date.",
    "try_again_moment": "Something unexpected happened, please try again in a moment.",
    "error_generate_qrcode": "Unable to generate QR Code. Please try again.",
    "payment_not_detected": "Payment not detected. Please check your banking app.",
    "reloaded_page_message": "The page needs to be reloaded!",
    "name_and_phone_required": "Name and phone number are required.",
    "enter_your_details": "Enter your details",
    "enter_your_name": "Enter your name",
    "enter_your_whatsapp": "Enter your WhatsApp",
    "enter_your_date_of_birth": "Enter your date of birth",
    "review_change_amount": "Review the Change Amount!",
    "no_internet_connection": "No Internet connection"
  }
}
